$('.slider-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  fade: true,
  rtl: true,
  arrows: false,
  asNavFor: '.slider-nav',
  responsive: [
{
    breakpoint: 768,
    settings: {
      dots: true,
    }
}
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object
]
});
$('.slider-nav').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.slider-for',
  dots: true,
  rtl: true,
  arrows: false,
  focusOnSelect: true
});